<template>
  <div class="page-wrapper">
    <form @submit="saveArticle($event)">
      <div>
        <label for="name">Ajouter une image</label>
        <input
          type="file"
          name="file"
          accept=".png, .jpg"
          :required="mode === 'create'"
          @change="uploadFeaturedImage($event)"
        />
      </div>
      <div class="featured-image-preview">
        <img v-if="featuredImagePreview" :src="featuredImagePreview" />
        <img v-else-if="!featuredImagePreview && article.featuredImageUrl" :src="article.featuredImageUrl" />
      </div>
      <div>
        <label for="name">Ajouter un titre</label>
        <input id="name" v-model="article.name" type="text" required />
      </div>
      <div>
        <label for="quillEditor">Ajouter le contenu de l'artcile</label>
        <quill-editor id="quillEditor" ref="quillEditor" v-model="article.content" :options="quillOptions" />
      </div>
      <div v-if="!article.isPublished">
        <label>Cet article est en brouillon</label>
      </div>
      <div v-else>
        <label>Cet article sera publié</label>
      </div>
      <button type="submit" class="bt" :class="{ 'is-loading': isLoading }" @mouseover="setArticleStatus(false)">
        <div class="bt-text">
          <p>Sauvegarder et prévisualiser</p>
        </div>
        <SmallLoader />
      </button>
      <button type="submit" class="bt" :class="{ 'is-loading': isLoading }" @mouseover="setArticleStatus(true)">
        <div class="bt-text">
          <p>Sauvegarder et publier</p>
        </div>
        <SmallLoader />
      </button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isNil } from 'lodash'
import { quillEditor } from 'vue-quill-editor'
import SmallLoader from '@/components/particles/SmallLoader'
import { nanoid } from 'nanoid'
import { storageRef, firebase } from '@/firebase/init'
import ArticlesDB from '@/firebase/articles-db'

export default {
  components: {
    quillEditor,
    SmallLoader
  },
  head() {
    return {
      title: {
        inner: 'Articles'
      },
      meta: [
        {
          name: 'description',
          content: ``,
          id: 'desc'
        }
      ],
      link: [
        {
          rel: 'stylesheet',
          href: 'https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.snow.min.css'
        }
      ]
    }
  },
  data: () => ({
    article: {
      content: '',
      isPublished: false
    },
    mode: 'create',
    featuredImagePreview: '',
    isLoading: false,
    quillOptions: {
      modules: {
        toolbar: [
          [{ size: ['small', false, 'large'] }], // custom dropdown
          [{ header: [2, 3, 4, false] }],
          ['bold', 'italic', 'underline'], // toggled buttons
          ['blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['image', 'video']
        ]
      },
      theme: 'snow'
    }
  }),
  computed: {
    ...mapState('authentication', ['user'])
  },
  watch: {
    $route: {
      async handler($route) {
        if (!isNil($route.params.articleId) && $route.params.articleId) {
          this.isLoading = true
          const { articleId } = $route.params
          const articlesDB = new ArticlesDB()
          this.article = await articlesDB.read(articleId)
          this.mode = 'update'
          this.isLoading = false
        }
      },
      immediate: true
    }
  },
  methods: {
    async uploadFeaturedImage(event) {
      const uploadedFile = event.target.files[0]

      if (!uploadedFile) {
        return
      }

      const acceptedFormats = ['image/jpeg', 'image/png']

      // Validate Format
      if (!acceptedFormats.includes(uploadedFile.type)) {
        console.log('Err: File Type')
        return
      }

      // Validate Size
      if (uploadedFile.size / 1000 > 2500) {
        console.log('Err: File Type')
        return
      }

      // Show the preview to the user
      const reader = new FileReader()
      reader.readAsDataURL(uploadedFile)
      reader.onload = () => {
        this.featuredImagePreview = reader.result
      }

      // Create a unique name/sku for the image
      const fileName = `${nanoid()}-${uploadedFile.name}`

      const uploadTask = storageRef.child(`articles/${fileName}`).put(uploadedFile)

      this.isLoading = true

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.imageProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running')
              break
            default:
              break
          }
        },
        error => {
          // Handle unsuccessful uploads
          this.creationError = error
          this.isLoading = false
        },
        () => {
          setTimeout(async () => {
            this.article.featuredImage = fileName
            this.article.featuredImageFormats = '300x300,600x600,1024x768'

            // Create a reference to the user's cropped imaged
            const format = this.article.featuredImageFormats.split(',')[2]
            let imageWithFormat = this.article.featuredImage.replace('.png', '').replace('.jpg', '')
            imageWithFormat = `${imageWithFormat}_${format}.png`

            try {
              // Create a reference to the file we want to download
              const imageRef = storageRef.child(`articles/thumbs/${imageWithFormat}`)
              // Get the download URL
              this.article.featuredImageUrl = await imageRef.getDownloadURL()
            } catch (e) {
              this.article.featuredImageUrl = null
              console.log(e)
            } finally {
              this.isLoading = false
            }
          }, 5000)
        }
      )
    },
    setArticleStatus(value) {
      this.article.isPublished = value
    },
    async saveArticle(e) {
      e.preventDefault()

      // Validate Content
      if (!this.article.content) {
        console.log('Err: Content')
        return
      }

      this.isLoading = true

      const article = { ...this.article }
      const articlesDB = new ArticlesDB()

      if (this.mode === 'create') {
        await articlesDB.create(article)
      } else {
        await articlesDB.update(article)
      }

      this.article = {
        content: '',
        isPublished: false
      }
      this.isLoading = false
      this.$router.push({ name: 'admin/messages' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/quill-editor.scss';

form {
  max-width: 800px;
}

.featured-image-preview {
  img {
    display: block;
    width: 100%;
  }
}
</style>
